<style scoped>
.img_box {
  padding: 5px 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}
</style>

<template>
  <div>
    <Row>
      <i-col span="24">
        <h4 class="workplatform-title m-b-10">制作工单基本信息</h4>
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="8"><span class="title">工单日期</span>{{ produceBean.actionDate }}</i-col>
      <i-col span="8"><span class="title">工单创建时间</span>{{ produceBean.createTime }}</i-col>
      <i-col span="8"><span class="title">供应商</span>{{ produceBean.companyName }}</i-col>
    </Row>
    <Row>
      <i-col span="24"><span class="title">所属品牌</span>{{ produceBean.name }}</i-col>
    </Row>

    <Tabs value="name1">
      <TabPane label="制作详情" name="name1">
        <Row class="p-b-5">
          <i-col span="24">
            分组类型：
            <div class="p-l-10 p-r-10 m-b-2" @click="changeGroupType(1)" :class="groupBy === 1
              ? 'workplatform-radio-button-active'
              : 'workplatform-radio-button'
              ">
              按资源类型
            </div>
            <div class="p-l-10 p-r-10 m-b-2" @click="changeGroupType(2)" :class="groupBy === 2
              ? 'workplatform-radio-button-active'
              : 'workplatform-radio-button'
              ">
              按站点
            </div>
          </i-col>
        </Row>

        <Row class="p-b-5">
          <i-col span="24">
            <label v-if="groupBy === 1" style="width: 63px; display: inline-block">资源类型：</label>
            <label v-else style="width: 63px; display: inline-block">站点：</label>
            <div class="p-l-10 p-r-10 m-b-2" @click="handleChangeType(0)" :class="chooseSecondeType === 0
              ? 'workplatform-radio-button-active'
              : 'workplatform-radio-button'
              ">
              全部
            </div>
            <div v-for="item in secondTypes" :key="item.id" class="p-l-10 p-r-10 m-b-2" @click="handleChangeType(item.id)"
              :class="chooseSecondeType === item.id
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
                ">
              {{ item.name }}
            </div>
          </i-col>
        </Row>
        <Row class="p-b-5">
          <i-col span="24">
            规格尺寸：
            <div class="p-l-10 p-r-10 m-b-2" @click="handleChangeSize(0)" :class="currentSize === 0
              ? 'workplatform-radio-button-active'
              : 'workplatform-radio-button'
              ">
              全部
            </div>
            <div v-for="(item, index) in sizeList" :key="index" class="p-l-10 p-r-10 m-b-2"
              @click="handleChangeSize(item)" :class="currentSize === item
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
                ">
              {{ item }}
            </div>
          </i-col>
        </Row>
        <h4 class="workplatform-title m-t-10 m-b-10">
          制作画面({{ showScreenList.length }}张)<Button type="success" size="small" class="m-l-10"
            @click="handleBatchDownloadQRcode">批量下载二维码</Button>
        </h4>

        <Row :gutter="16">
          <i-col span="4" v-for="(item, index) in showScreenList" :key="index"
            :style="index % 6 === 0 ? 'clear:both' : ''" class="m-b-10">
            <div class="img_box" @click="showBigImg(index)">
              <img :src="item.fileKey + '?x-oss-process=image/resize,h_150'" width="100% " height="150px"
                title="点击查看大图 " />
            </div>
            <div class="m-t-5 text-center">
              {{ item.name }}（{{ item.width }}mm x {{ item.height }}mm /
              {{ item.quantity }} 张）
            </div>
            <div class="m-t-5 text-center">
              批次号：{{ item.batchNumber }}
            </div>
            <div class="m-t-5 text-center">
              <a href="javascript:void(0);" @click="handleDownloadQRcode(item)">下载二维码</a>
            </div>
          </i-col>
        </Row>
      </TabPane>
      <TabPane label="制作进度" name="name2">
        <Row class="p-b-5">
          <i-col span="12">
            制作进度：
            <RadioGroup v-model="progressQuery.status" type="button" size="small" @on-change="handleStatusChange">
              <Radio :label="item.status" v-for="item in progressStatusArray" :key="item.status">
                <Badge :count="item.quantity">
                  <span>{{ item.showStatusName }}</span>
                </Badge>
              </Radio>
              <!-- <Radio label="2">
                <Badge :count="0">
                <span>执行中</span>
                </Badge>
              </Radio>
              <Radio label="3">
                <Badge :count="25">
                <span>已完成</span>
                </Badge>
              </Radio> -->
            </RadioGroup>
          </i-col>
          <i-col span="12" style="text-align:right;">
            <div>
              <Button class="m-r-5" type="success" size="small" v-if="progressQuery.status === 1"
                :disabled="feeitemIds.length === 0" @click="handleStart">开始制作</Button>
              <Button type="error" class="m-r-5" size="small" v-if="progressQuery.status === 2 && serviceType === 2"
                :disabled="feeitemIds.length === 0" @click="handleCancel">取消制作</Button>
              <Button type="success" class="m-r-5" size="small" v-if="progressQuery.status === 2"
                :disabled="feeitemIds.length === 0" @click="handleComplate">完成制作</Button>
              <!-- <Button
                type="success"
                size="small"
                @click="downloadMakeOrder"
              >下载制作工单</Button> -->
            </div>

          </i-col>
        </Row>

        <Row>
          <i-col span="24">
            <Table stripe max-height="620" size="small" :data="progressArray" :columns="tableColumn" :loading="tableLoading"
              @on-selection-change="selectionChange"></Table>
            <div class="paging_style">
              <Page size="small" :total="total" :page-size="progressQuery.pageSize" show-total show-sizer show-elevator
                :current="progressQuery.pageNumber" :page-size-opts="pagesizeSetting"
                @on-page-size-change='changePageSize' @on-change="handlePageNumberChange"></Page>
            </div>
          </i-col>
        </Row>

      </TabPane>
    </Tabs>

    <!-- 弹窗显示制作照片大图 -->
    <Modal v-model="imgShowModal" width="800" footer-hide>
      <p slot="header" style="text-align: center">
        {{ currentImg.name }}-{{ currentImg.screenName }}（{{
          currentImg.quantity
        }}
        张）
      </p>
      <div class="text-center">
        <img class="bigImg" :src="currentImg.fileKey + '?x-oss-process=image/resize,w_760'" />
      </div>
    </Modal>
    <Modal v-model="visiblePreview" width="800" :footer-hide="true">
      <p slot="header" class="text-center">{{ fileName }} {{ variableTitle }}</p>
      <Row class="m-b-5">
        <i-col class="text-right">
          <Button size="small" type="success" @click="viewBigImage">查看原图</Button>
        </i-col>
      </Row>
      <img-light-box ref="imgLightBox" :sortIndex.sync="sortIndex"></img-light-box>
    </Modal>
  </div>
</template>

<script>
// import { formatMspPlanStatusColor } from '@/utils/tagStatus'
import {
  getScreenStatisticalGroupByResourceType,
  getScreenStatisticalGroupByStation,
  confirmedProduce,
  finishProduce,
  getstatusquantitylist,
  cancelProduce
} from '@/api/msp/produce'
import { getSupplyTaskitemPage } from '@/api/msp/taskitem'
import ImgLightBox from '@/components/common/ImgVideoLightBox'

import { downloadFileRequest } from '@/utils/download'

export default {
  components: {
    ImgLightBox
  },
  props: {
    serviceType: {
      // 服务类型，1：代理商，2：供应商
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      groupBy: 1, // 分组类型
      listByResourceType: [], // 所有的按资源类型分组的制作照片
      listByStaion: [], // 所有按站点分组的制作照片

      secondTypes: [], // 二级查询分组
      chooseSecondeType: 0,

      sizeList: [],
      currentSize: 0,
      showScreenList: [],

      imgShowModal: false,
      currentImg: {},

      // 任务进度
      progressStatusArray: [],

      sortIndex: 0,
      visiblePreview: false,
      fileName: '',
      variableTitle: '',

      progressArray: [],
      progressQuery: {
        pageSize: 15,
        pageNumber: 1,
        status: 0,
        type: 3
      },
      pagesizeSetting: [15, 50, 100, 200, 500],
      total: 0,
      tableLoading: false,
      tableColumn: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '资源编号', key: 'resourceCode', align: 'center' },
        { title: '所属站台', key: 'domainName', align: 'center' },
        { title: '作业日期', key: 'actionDate', align: 'center' },
        {
          title: '作业画面',
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemFiles && params.row.taskitemFiles.length > 0) {
              return h('div', [
                h(
                  'img',
                  {
                    domProps: {
                      src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                    },
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.taskitemFiles
                        }
                        this.sortIndex = 0
                        this.fileName = `${params.row.domainName} ${params.row.code}`
                        this.variableTitle = ''
                        this.currentFileArray = params.row.taskitemFiles
                        this.$refs.imgLightBox.init(postData)
                        this.visiblePreview = true
                      }
                    },
                    style: {
                      width: '64px'
                    }
                  }
                )
              ])
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        { title: '材质', align: 'center', key: 'serviceName' },
        {
          title: '作业名称',
          align: 'center',
          render: (h, params) => {
            return h('span', Array.from(new Set(params.row.taskitemFiles.map(x => x.fileName))))
          }
        },
        {
          title: '状态',
          key: 'statusName',
          align: 'center',
          render: (h, params) => {
            const html = []
            switch (params.row.status) {
              case 1:
                html.push(h('span', '待制作'))
                break
              case 2:
                html.push(h('span', '制作中'))
                break
              case 3:
                html.push(h('span', '已完成'))
                break
            }
            if (params.row.hangUpStatus === 2) { // 挂起
              html.push(h('div', [
                h('Tag', {
                  props: {
                    color: 'warning'
                  }
                }, '已挂起')
              ]))
            }
            return h('div', html)
          }
        }
      ],

      currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合

      feeitemIds: [] // 当前所有选中项的主键集合
    }
  },
  created () {
    this.progressQuery.actionDate = this.produceBean.actionDate
    this.progressQuery.supplyCompanyId = this.produceBean.companyId
    this.progressQuery.taskId = this.produceBean.id
    this.initPageData()
    this.getStatusArray()
  },
  computed: {
    produceBean () {
      return this.$store.state.produceOrder.produceInfo
    }
  },
  methods: {
    // loadData () {
    //   this.initPageData()
    // },
    initPageData () {
      const postData = {
        taskId: this.produceBean.id,
        actionDate: this.produceBean.actionDate,
        companyId: this.produceBean.companyId
      }
      const getListByResourceType = getScreenStatisticalGroupByResourceType(
        postData
      ).then((res) => {
        this.listByResourceType = res
      })
      const getListByStations = getScreenStatisticalGroupByStation(
        postData
      ).then((res) => {
        this.listByStaion = res
      })
      Promise.all([getListByResourceType, getListByStations]).then(() => {
        this.formatSecondData()
      })
    },
    formatSecondData () {
      this.secondTypes = []
      this.sizeList = []
      this.screenList = []
      const list = this.groupBy === 1 ? this.listByResourceType : this.listByStaion

      // 获取资源类型
      list.forEach((x) => {
        // 获取二级分类
        if (!this.secondTypes.some((item) => item.id === x.id)) {
          this.secondTypes.push({ id: x.id, name: x.name })
        }
        // 获取尺寸分类
        x.screenSizeVOList.forEach((sizeVO) => {
          if (
            !this.sizeList.some(
              (item) => item === sizeVO.width + 'mm x ' + sizeVO.height + 'mm'
            )
          ) {
            this.sizeList.push(sizeVO.width + 'mm x ' + sizeVO.height + 'mm')
          }

          // 封装所有素材数据
          sizeVO.screenBeanList.forEach((screen) => {
            this.screenList.push({
              id: x.id,
              name: x.name,
              height: sizeVO.height,
              width: sizeVO.width,
              fileId: screen.fileId,
              fileKey: screen.fileKey,
              fileKeyWithNotFullPath: screen.fileKeyWithNotFullPath,
              quantity: screen.quantity,
              screenName: screen.screenName,
              batchNumber: screen.batchNumber,
              batchIds: screen.batchIds
            })
          })
        })
      })
      this.showScreenList = this.screenList
    },
    changeGroupType (type) {
      this.groupBy = type
      this.chooseSecondeType = 0
      this.formatSecondData()
    },
    handleChangeType (type) {
      this.chooseSecondeType = type
      if (type === 0) {
        this.showScreenList = this.screenList
      } else {
        this.showScreenList = this.screenList.filter((x) => x.id === type)
      }
    },
    handleChangeSize (size) {
      this.currentSize = size
      if (size === 0) {
        this.showScreenList = this.screenList
      } else {
        this.showScreenList = this.screenList.filter(
          (x) => x.width + 'mm x ' + x.height + 'mm' === size
        )
      }
    },
    showBigImg (index) {
      // 给图片索引赋值
      this.currentImg = this.showScreenList[index]
      this.imgShowModal = true
    },
    // formatStatusTagColor (status) {
    //   return formatMspPlanStatusColor(status)
    // },
    handleStart () {
      if (!this.feeitemIds.length) {
        this.$Notice.info({ desc: '请至少选择一个工单任务！' })
        return
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定开始制作选中的工单任务？',
        onOk: () => {
          confirmedProduce({
            actionDate: this.produceBean.actionDate,
            taskId: this.produceBean.id,
            companyId: this.produceBean.companyId,
            taskitemIds: JSON.stringify(this.feeitemIds)
          }).then((res) => {
            if (res && res.errcode === 0) {
              this.getStatusArray()
              this.feeitemIds = []
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleComplate () {
      if (!this.feeitemIds.length) {
        this.$Notice.info({ desc: '请至少选择一个工单任务！' })
        return
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定已完成选中的工单任务？',
        onOk: () => {
          finishProduce({
            actionDate: this.produceBean.actionDate,
            taskId: this.produceBean.id,
            companyId: this.produceBean.companyId,
            taskitemIds: JSON.stringify(this.feeitemIds)
          }).then((res) => {
            if (res && res.errcode === 0) {
              this.getStatusArray()
              this.feeitemIds = []
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 取消开始制作
     */
    handleCancel () {
      if (!this.feeitemIds.length) {
        this.$Notice.info({ desc: '请至少选择一个工单任务！' })
        return
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要取消制作？',
        onOk: () => {
          cancelProduce({
            actionDate: this.produceBean.actionDate,
            taskId: this.produceBean.id,
            companyId: this.produceBean.companyId,
            taskitemIds: JSON.stringify(this.feeitemIds)
          }).then((res) => {
            if (res && res.errcode === 0) {
              this.getStatusArray()
              this.feeitemIds = []
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    downloadMakeOrder () {
      const filename = '制作工单明细(' + this.produceBean.name + ' ' + this.produceBean.actionDate + ').xlsx'
      const params = {
        taskId: this.produceBean.id,
        companyId: this.$store.getters.token.userInfo.companyId

      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/createproducedetailexcel', params, filename)
    },

    // 获取制作单状态数量
    getStatusArray () {
      const postData = {
        taskId: this.produceBean.id,
        actionDate: this.produceBean.actionDate,
        companyId: this.produceBean.companyId
      }
      getstatusquantitylist(postData).then(res => {
        this.progressStatusArray = res
        this.progressStatusArray.forEach(item => {
          switch (item.status) {
            case 1:
              item.showStatusName = '待制作'
              break
            case 2:
              item.showStatusName = '制作中'
              break
            case 3:
              item.showStatusName = '已完成'
              break
          }
        })
        if (!this.progressStatusArray.filter(x => x.status === this.progressQuery.status).length) {
          this.progressQuery.status = this.progressStatusArray[0].status
          this.progressQuery.pageNumber = 1
        }

        this.getTaskItems()
      })
    },

    // 获取制作进度的列表
    getTaskItems () {
      const that = this
      that.tableLoading = true

      getSupplyTaskitemPage(that.progressQuery).then(res => {
        if (res.totalRow && !res.list.length) {
          that.progressQuery.pageNumber = that.progressQuery.pageNumber - 1
          that.getTaskItems()
        } else {
          that.total = res.totalRow
          res.list.map((item) => {
            item._checked = that.feeitemIds.includes(item.id)
            item._disabled = item.status === 3 || item.hangUpStatus === 2 // 禁用已完成或者已挂起任务
            return item
          })
          that.currentPageAllItemIds = res.list.map(item => {
            return item.id
          })
          that.progressArray = res.list
        }
      }).finally(() => { that.tableLoading = false })
    },
    handlePageNumberChange (page) {
      this.progressQuery.pageNumber = page
      this.getTaskItems()
    },
    changePageSize (pageSize) {
      this.progressQuery.pageNumber = 1
      this.progressQuery.pageSize = pageSize
      this.getTaskItems()
    },
    handleStatusChange () {
      this.progressQuery.pageNumber = 1
      this.currentPageAllItemIds = []
      this.feeitemIds = []
      this.getTaskItems()
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    },
    // 列表选中项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map(item => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach(item => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
          }
        } else { // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    /**
     * 批量下载二维码
     */
    handleBatchDownloadQRcode () {
      let height = null
      let width = null
      if (this.currentSize !== 0 && this.currentSize.length) {
        const sizeArray = this.currentSize.split('x')
        height = parseInt(sizeArray[0].trim().substring(0, sizeArray[0].trim().length - 2))
        width = parseInt(sizeArray[1].trim().substring(0, sizeArray[1].trim().length - 2))
      }
      const params = {
        actionDate: this.produceBean.actionDate,
        companyId: this.produceBean.companyId,
        taskId: this.produceBean.id,
        resourcetypeId: this.chooseSecondeType === 0 ? null : this.chooseSecondeType,
        height,
        width

      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/batchdownloadQRCode', params, '')
    },
    /**
     * 单个下载二维码
     */
    handleDownloadQRcode (file) {
      const params = {
        actionDate: this.produceBean.actionDate,
        companyId: this.produceBean.companyId,
        batchIds: file.batchIds,
        taskId: this.produceBean.id
      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/getQRCodebyids', params, '')
    }
  }
}
</script>
