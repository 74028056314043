import request from '@/utils/requestV2'
const qs = require('qs')

// 手动创建工单
export function getProducePage (data) {
  return request({
    url: '/ooh-msp/v2/produce/getproducepage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 制作画面按资源类型分组统计
export function getScreenStatisticalGroupByResourceType (data) {
  return request({
    url: '/ooh-msp/v2/produce/getscreenstatisticalgroupbyresourcetype',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 制作画面按站点分组统计
export function getScreenStatisticalGroupByStation (data) {
  return request({
    url: '/ooh-msp/v2/produce/getscreenstatisticalgroupbystation',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 供应商分页获取制作列表
export function getSupplyRoducePage (data) {
  return request({
    url: '/ooh-msp/v2/produce/getsupplyproducepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 制作公司确认开始制作(制作公司)
export function confirmedProduce (data) {
  return request({
    url: '/ooh-msp/v2/produce/confirmproduce',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 制作公司取消开始制作(制作公司)
export function cancelProduce (data) {
  return request({
    url: '/ooh-msp/v2/produce/cancelproduce',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 完成制作
export function finishProduce (data) {
  return request({
    url: '/ooh-msp/v2/produce/finishproduce',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取制作单的状态数量
export function getstatusquantitylist (data) {
  return request({
    url: '/ooh-msp/v2/produce/getstatusquantitylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
